/**
 * Common JavaScript functions for the PrecisionCreative WordPress theme.
 * This file should be called in the footer of the theme.
 * Yell at Grayson if this code stops working.
 *
 * @summary Precision Creative Theme common JavaScript functions
 * @author Precision Creative Dev Team
 *
 * Created: September 23, 2020
 */

/**
 * Transform theme menus into multi-level clicky menus.
 * @param {HTMLElement} menu The main `<ul>` element.
 */
const Menu = function (menu) {
  /**
   * Called on an instance when ready to run the menu code.
   */
  this.init = function () {
    menuSetup()

    document.addEventListener('click', closeOpenedMenus)
  }

  /**
   * One-time menu setup function.
   */
  function menuSetup() {
    menu.classList.remove('no-js')

    menu.querySelectorAll('ul.sub-menu').forEach((submenu) => {
      const button = convertLinkToButton(submenu)

      setupAria(submenu, button)

      button.addEventListener('click', handleButtonClick)
    })
  }

  /**
   * Handle the click event of a button.
   * @param {ClickEvent} e The event.
   */
  function handleButtonClick(e) {
    const button = e.currentTarget
    const submenu = getSubmenuFromButton(button)

    if (isOpen(submenu)) {
      switchClosed(submenu, button)
    } else {
      switchOpen(submenu, button)
    }
  }

  /**
   * Open a submenu.
   * @param {HTMLElement} submenu The submenu to open.
   */
  function switchOpen(submenu) {
    const button = getButtonFromSubmenu(submenu)

    /**
     * Close other menus at its matched depth or deeper
     */
    submenu
      .closest(`ul:not(#${submenu.id})`)
      .querySelectorAll(`ul.sub-menu:not(${submenu.id})`)
      .forEach((childSubmenu) => {
        switchClosed(childSubmenu)
      })

    submenu.setAttribute('aria-hidden', 'false')
    button.setAttribute('aria-expanded', 'true')

    checkMenuOverflow(submenu)
  }

  /**
   * Close a submenu.
   * @param {HTMLElement} submenu The submenu to close.
   */
  function switchClosed(submenu) {
    const button = getButtonFromSubmenu(submenu)

    submenu.setAttribute('aria-hidden', 'true')
    button.setAttribute('aria-expanded', 'false')

    submenu.classList.remove('sub-menu--flip')

    /**
     * Close all child submenus.
     */
    submenu.querySelectorAll('ul.sub-menu').forEach((childSubmenu) => {
      switchClosed(childSubmenu)
    })
  }

  /**
   * Checks an opened submenu to see if it is overflowing the screen.
   *
   * Adds a class to allow for styling the submenu in the opposite direction.
   *
   * @param {HTMLElement} submenu The submenu to check.
   */
  function checkMenuOverflow(submenu) {
    const submenuBoundingRect = submenu.getBoundingClientRect()
    const submenuWidth = submenu.offsetWidth
    const submenuLeft = submenuBoundingRect.left
    const submenuRight = submenuWidth + submenuLeft
    const bodyWidth = document.body.clientWidth

    if (submenuRight > bodyWidth) {
      submenu.classList.add('sub-menu--flip')
    }
  }

  /**
   * Close all open menus when clicking outside of the menu.
   * @param {ClickEvent} e The click event.
   */
  function closeOpenedMenus(e) {
    const closestMenu = e.target.closest(`#${getMenuParentId()}`)

    /**
     * If the click event is outside of menus, close all submenus.
     */
    if (!closestMenu) {
      menu.querySelectorAll('ul.sub-menu').forEach((submenu) => {
        switchClosed(submenu)
      })
    }
  }

  /**
   * Test to see if a submenu is open or not.
   * @param {HTMLElement} submenu The submenu to check for.
   * @returns {Boolean} Whether or not the submenu is open.
   */
  function isOpen(submenu) {
    return submenu.getAttribute('aria-hidden') === 'true' ? false : true
  }

  /**
   * Get a submenu from its corresponding button element.
   * @param {HTMLElement} button The button that corresponds to the submenu.
   * @return {HTMLElement} The submenu.
   */
  function getSubmenuFromButton(button) {
    const submenu = document.getElementById(button.getAttribute('aria-controls').slice(1))

    return submenu
  }

  /**
   * Get a button that belongs to a submenu.
   * @param {HTMLElement} submenu The submenu to get the corresponding button.
   * @returns {HTMLElement} The button that belongs to the passed submenu.
   */
  function getButtonFromSubmenu(submenu) {
    return submenu.parentElement.getElementsByTagName('button')[0]
  }

  /**
   * Get the ID of a submenu.
   * @param {HTMLElement} submenu The submenu to get the ID from.
   * @returns {String} The submenu ID.
   */
  function getSubmenuId(submenu) {
    return submenu.getAttribute('id')
  }

  /**
   * Get the ID of the menu's parent element.
   * @returns {String} The ID.
   */
  function getMenuParentId() {
    return menu.parentElement.getAttribute('id')
  }

  /**
   * Generate an ID for a submenu element.
   * @param {HTMLElement} submenu The submenu to set the ID for.
   * @returns {String} The generated ID.
   */
  function setSubmenuId(submenu) {
    let id = submenu.getAttribute('id')
    const button = getButtonFromSubmenu(submenu)

    if (null === id) {
      id = button.textContent.trim().replace(/\s+/g, '-').toLowerCase()
    }

    id += '-submenu'

    if (button.closest('#pushy')) {
      id += '-pushy'
    }

    submenu.setAttribute('id', id)

    return getSubmenuId(submenu)
  }

  /**
   * Setup the aria attributes for a submenu and its button.
   * @param {HTMLElement} submenu The submenu to set the up the aria for.
   * @param {HTMLElement} button The button that belongs to the submenu passed.
   */
  function setupAria(submenu, button) {
    const id = setSubmenuId(submenu)

    button.setAttribute('aria-controls', `#${id}`)
    button.setAttribute('aria-expanded', 'false')

    submenu.setAttribute('id', id)
    submenu.setAttribute('aria-hidden', 'true')
  }

  /**
   * Convert a submenu's anchor tag into a button tag.
   * @param {HTMLElement} submenu The submenu that belongs to the link.
   * @returns {HTMLElement} The newly created button.
   */
  function convertLinkToButton(submenu) {
    const submenuParent = submenu.parentElement
    const link = submenuParent.getElementsByTagName('a')[0]
    const linkHTML = link.innerHTML
    const linkAtts = link.attributes
    const button = document.createElement('button')

    if (null !== link) {
      button.innerHTML = linkHTML.trim()

      for (let i = 0, len = linkAtts.length; i < len; i++) {
        let attr = linkAtts[i]

        if ('href' !== attr.name) {
          button.setAttribute(attr.name, attr.value)
        }
      }

      submenuParent.replaceChild(button, link)
    }

    return button
  }
}

/**
 * The logic for handling the pushy menu
 * @param {HTMLElement} hamburger The hamburger button that opens the pushy menu
 */
const PushyMenu = function (hamburger) {
  const pushy = document.getElementById('pushy'),
    closePushy = pushy.querySelector('.pushy__close')

  this.init = function () {
    pushySetup()
    setupAria()

    document.addEventListener('click', closeOpenPushy)
  }

  // Open / close functions

  function togglePushy() {
    if ('true' === hamburger.getAttribute('aria-expanded')) {
      hamburger.setAttribute('aria-expanded', false)
      pushy.setAttribute('aria-hidden', true)
    } else {
      hamburger.setAttribute('aria-expanded', true)
      pushy.setAttribute('aria-hidden', false)
      pushy.focus()
    }
  }

  function closeOpenPushy(e) {
    if (!e.target.closest(`#${pushy.id}`) && !hamburger.parentElement.contains(e.target)) {
      hamburger.setAttribute('aria-expanded', false)
      pushy.setAttribute('aria-hidden', true)
    }
  }

  // Setup functions

  function pushySetup() {
    hamburger.addEventListener('click', togglePushy)
    closePushy.addEventListener('click', togglePushy)
  }

  function setupAria() {
    hamburger.setAttribute('aria-controls', `#${pushy.id}`)
    hamburger.setAttribute('aria-expanded', false)

    pushy.setAttribute('aria-hidden', true)
  }
}

/**
 * The logic for handling the accordion menu
 * @param {HTMLElement} hamburger The hamburger button that opens the accordion menu
 */
const AccordionMenu = function (hamburger) {
  const accordion = document.getElementById('accordion')

  this.init = function () {
    setupAria()
    eventListeners()
  }

  function closeAccordion() {
    hamburger.setAttribute('aria-expanded', 'false')
    accordion.setAttribute('aria-hidden', 'true')

    accordion.style.height = '0px'
  }

  function openAccordion() {
    const height = accordion.children[0].offsetHeight

    hamburger.setAttribute('aria-expanded', 'true')
    accordion.setAttribute('aria-hidden', 'false')

    accordion.style.height = `${height}px`
  }

  function toggleAccordion() {
    if (hamburger.getAttribute('aria-expanded') === 'true') {
      closeAccordion()
    } else {
      openAccordion()
    }
  }

  // Setup functions

  function eventListeners() {
    hamburger.addEventListener('click', toggleAccordion)
  }

  function setupAria() {
    hamburger.setAttribute('aria-controls', `#${accordion.id}`)
    hamburger.setAttribute('aria-expanded', 'false')

    accordion.setAttribute('aria-hidden', 'true')
  }
}

/**
 * On DOMContentLoaded, initialize the menus for the site
 */
document.addEventListener('DOMContentLoaded', function () {
  const menus = document.querySelectorAll('.navbar__links, .pushy__links')
  const accordionHamburgers = document.querySelectorAll('.hamburger--accordion')
  const pushyHamburgers = document.querySelectorAll('.hamburger--pushy')

  accordionHamburgers.forEach((hamburger) => {
    const accordionInstance = new AccordionMenu(hamburger)
    accordionInstance.init()
  })

  pushyHamburgers.forEach((hamburger) => {
    const hamburgerInstance = new PushyMenu(hamburger)
    hamburgerInstance.init()
  })

  menus.forEach((menu) => {
    const menuInstance = new Menu(menu)
    menuInstance.init()
  })
})

/**
 * Intersection Observer for animations
 */
;(function () {
  const repeatAnimations = true

  function observerCallback(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('is-intersecting')
      } else {
        if (true === repeatAnimations) {
          entry.target.classList.remove('is-intersecting')
        }
      }
    })
  }

  const options = {
    threshold: [0, 0.5],
    rootMargin: '-20px 0px -100px 0px',
  }

  const observer = new IntersectionObserver(observerCallback, options)

  document.addEventListener('DOMContentLoaded', function () {
    const sections = document.querySelectorAll('.has-animations')

    sections.length > 0 &&
      sections.forEach((section) => {
        observer.observe(section)
      })
  })
})()

/**
 * Loads the fonts necessary for text balancing, and runs the balancer.
 */
function loadFonts() {
  if (window.FontFaceObserver === undefined) {
    console.error('Could not load the font face observer scripts!')
    return
  }

  const observers = []
  const fontFaces = [
    {
      name: 'Gotham',
      data: {
        weight: 400,
      },
    },
    {
      name: 'Gotham',
      data: {
        weight: 700,
      },
    },
  ]

  fontFaces.forEach(({ name, data }) => {
    const observer = new FontFaceObserver(name, data)
    observers.push(observer.load(null, 3000))
  })

  Promise.all(observers)
    .then(runTextBalancer)
    .catch(function () {
      console.warn(
        'Error loading fonts. Did you update the fonts in the balanceTexts function of main.js?'
      )
    })
}
loadFonts()

/**
 * Runs the text blanacer scripts.
 */
function runTextBalancer() {
  if (window.textBalancer === undefined) {
    console.error('Could not load the text balancer script!')
    return
  }

  textBalancer.initialize()
}
